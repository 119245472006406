/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('points-learn-more', {
      parent: 'root',
      url: '/points-learn-more',
      templateUrl:
        'apps/points-learn-more/templates/acp-points-learn-more.ng.html',
      controller: 'AcpPointsLearnMoreCtrl',
      data: {
        gaLocation: 'points-learn-more',
        permissions: {
          except: ['isDashboardRedesignEnabled'],
          redirectTo: {
            isDashboardRedesignEnabled: 'points-learn-more.rt'
          }
        }
      },
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.points-learn-more" */ 'apps/points-learn-more').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('points-learn-more.rt', {
      parent: 'root',
      url: '/points-learn-more/rt',
      controller: 'AcpPointsLearnMoreCtrl',
      templateUrl:
        'apps/points-learn-more/templates/acp-points-learn-more-rt.ng.html',
      permissions: {
        only: ['isDashboardRedesignEnabled'],
        redirectTo: {
          isDashboardRedesignEnabled: 'points-learn-more'
        }
      },
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.points-learn-more" */ 'apps/points-learn-more').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    });
}

export default states;
